<template>
  <div class="text-center">
    <v-dialog v-model="showModal" width="500" persistent>
      <v-card v-if="booking">
        <v-card-title class="text-h5 primary white--text">
          {{ booking.confirmation }}
        </v-card-title>
        <v-card-text v-if="!action">
          <div class="d-flex flex-wrap justify-center mb-6 pt-3">
            <v-btn
              class="mx-1 my-2"
              v-if="booking.status === 'ID required'"
              outlined
              color="primary"
              mt-4
              @click="action = 'checkIn'"
            >
              CHECK IN
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              v-if="booking.status === 'Active'"
              outlined
              color="primary"
              mt-4
              @click="checkOut()"
            >
              CHECK OUT
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              v-if="!booking.isSubscription && booking.status !== 'Inactive'"
              outlined
              color="primary"
              mt-4
              @click="extendBooking()"
            >
              EXTEND BOOKING
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              v-if="
                booking.isSubscription && !booking.billingDateOverrideAmount
              "
              outlined
              color="primary"
              mt-4
              @click="changeBilling()"
            >
              CHANGE BILLING DATE
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              outlined
              color="primary"
              mt-4
              @click="editBooking()"
            >
              EDIT BOOKING
            </v-btn>
            <v-btn
              class="mx-1 my-2"
              v-if="compShowCancelBookingButton"
              outlined
              color="primary"
              mt-4
              @click="cancelBooking()"
            >
              CANCEL BOOKING
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text v-if="action === 'checkIn'">
          <v-form ref="formCheckin" v-model="validCheckin" lazy-validation>
            <!-- CHECK IN -->
            <div class="mx-auto block text-center my-3">
              <v-icon large color="primary">mdi-card-account-details</v-icon>
            </div>
            <h4 v-if="booking.customerDetails.taxCode" class="my-5 text-center">
              <span class="primary--text"
                >{{ booking.customerDetails.title }}
                {{ booking.customerDetails.firstname }}
                {{ booking.customerDetails.lastname }}</span
              >
              has provided the following identification number:
            </h4>
            <h4 v-else class="my-5 text-center">
              <span class="primary--text"
                >{{ booking.customerDetails.title }}
                {{ booking.customerDetails.firstname }}
                {{ booking.customerDetails.lastname }}</span
              >
              has <span class="primary--text">not</span> yet provided an
              identification number:
            </h4>

            <v-text-field
              v-model="compTaxCode"
              label="ID Card or Passport Number"
              :rules="taxCodeRules"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text v-if="action === 'edit'">
          <!-- EDIT -->
          <h3 class="text-center primary--text my-3">
            EDIT BOOKING
          </h3>
          <v-form ref="form" v-model="validEditBooking" lazy-validation>
            <v-autocomplete
              v-model="edit.customerID"
              :items="compUsers"
              label="Customer"
              item-text="fullname"
              item-value="_id"
              persistent-hint
            >
            </v-autocomplete>
            <v-text-field
              disabled
              label="Unit No."
              v-model="edit.unitDetails.unitNumber"
              :rules="[rules.required]"
              required
            ></v-text-field>
            <v-select
              v-model="edit.status"
              :items="statusOptions"
              label="Status"
              :disabled="checkRole('status')"
            ></v-select>
            <v-menu
              v-model="dateEndMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edit.dateEnd"
                  label="End Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                v-model="edit.dateEnd"
                @input="dateEndMenu = false"
                :disabled="checkRole('dateEnd')"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              label="Payment Amount"
              v-model="edit.amount"
              prefix="฿"
              v-on:blur="handleBlur"
              :disabled="checkRole('paymentAmount')"
            ></v-text-field>
            <v-select
              label="Payment Method"
              v-model="edit.paymentMethod"
              :items="paymentMethods"
              :disabled="checkRole('paymentAmount')"
            ></v-select>
            <v-row v-if="edit.customerDetails.omiseId">
              <v-col sm="6">
                <v-switch
                  v-model="edit.isSubscription"
                  label="Subscription Booking?"
                ></v-switch>
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-if="edit.isSubscription"
                  v-model="edit.subscriptionPrice"
                  label="Subscription Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row v-if="edit.customerDetails.type === 'Corporate'">
              <v-checkbox
                v-model="edit.wtr"
                color="primary"
                required
                label="Withholding Tax Request"
              >
              </v-checkbox>
            </v-row> -->
            <v-textarea
              label="Booking Notes"
              v-model="edit.notes"
              hint="Booking notes, comments, etc."
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-text v-if="action === 'extend' && booking.status !== 'Overdue'">
          <!-- EDIT -->
          <h3 class="text-center primary--text my-3">
            EXTEND BOOKING
          </h3>
          <v-form ref="formExtend" v-model="validExtendBooking" lazy-validation>
            <v-text-field
              v-model="extend.weeks"
              outlined
              dense
              clearable
              label="Weeks"
              type="text"
              hide-details="auto"
              class="mb-2"
            >
              <template v-slot:prepend>
                <v-icon color="primary" @click="decrement('week')">
                  mdi-minus
                </v-icon>
              </template>
              <template v-slot:append-outer>
                <v-icon color="primary" @click="increment('week')">
                  mdi-plus
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="extend.months"
              outlined
              dense
              clearable
              label="Months"
              type="text"
              hide-details="auto"
              class="mb-2"
            >
              <template v-slot:prepend>
                <v-icon color="primary" @click="decrement('month')">
                  mdi-minus
                </v-icon>
              </template>
              <template v-slot:append-outer>
                <v-icon color="primary" @click="increment('month')">
                  mdi-plus
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="extend.sixMonths"
              outlined
              dense
              clearable
              label="6 Months"
              type="text"
              hide-details="auto"
              class="mb-2"
            >
              <template v-slot:prepend>
                <v-icon color="primary" @click="decrement('sixmonth')">
                  mdi-minus
                </v-icon>
              </template>
              <template v-slot:append-outer>
                <v-icon color="primary" @click="increment('sixmonth')">
                  mdi-plus
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="extend.years"
              outlined
              dense
              clearable
              label="Years"
              type="text"
              hide-details="auto"
              class="mb-2"
            >
              <template v-slot:prepend>
                <v-icon color="primary" @click="decrement('year')">
                  mdi-minus
                </v-icon>
              </template>
              <template v-slot:append-outer>
                <v-icon color="primary" @click="increment('year')">
                  mdi-plus
                </v-icon>
              </template>
            </v-text-field>
            <v-row class="mt-3">
              <v-col>
                <v-text-field
                  v-model="edit.dateEnd"
                  dense
                  disabled
                  label="End Date"
                  type="text"
                  hide-details="auto"
                  class="mb-2"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="compNewDateEnd"
                  dense
                  disabled
                  type="text"
                  hide-details="auto"
                  class="mb-2"
                >
                  <template v-slot:label>
                    <span style="color: #9c1b1f; font-weight=bold">NEW</span>
                    End Date
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Payment Method"
                  v-model="edit.paymentMethod"
                  dense
                  hide-details="auto"
                  :items="paymentMethods"
                  required
                ></v-select>
              </v-col>

              <!-- <v-col cols="12" v-if="edit.customerDetails.type === 'Corporate'">
                <v-checkbox
                  v-model="edit.wtr"
                  color="primary"
                  required
                  label="Withholding Tax Request"
                >
                </v-checkbox>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  label="Subtotal"
                  v-model="compExtendSubtotal"
                  dense
                  disabled
                  type="text"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- <v-col v-if='compExtendLatePaymentFee' cols="12">
                            <v-text-field label="Late Payment Fee" v-model="compExtendLatePaymentFee" dense disabled type="text" hide-details="auto" ></v-text-field>
                          </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Discount"
                  v-model="edit.customerDetails.credit"
                  dense
                  disabled
                  type="text"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Total"
                  v-model="compExtendTotal"
                  dense
                  disabled
                  type="text"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Booking Notes"
                  v-model="edit.notes"
                  hint="Booking notes, comments, etc."
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-text v-if="action === 'extend' && booking.status === 'Overdue'">
          <h3 class="text-center primary--text my-3">
            EXTEND BOOKING
          </h3>
          <v-row class="mt-3">
            <v-col>
              <v-text-field
                v-model="edit.dateEnd"
                dense
                disabled
                label="End Date"
                type="text"
                hide-details="auto"
                class="mb-2"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="compNewAutoDateEnd.newEndDate"
                dense
                disabled
                type="text"
                hide-details="auto"
                class="mb-2"
              >
                <template v-slot:label>
                  <span style="color: #9c1b1f; font-weight=bold">NEW</span> End
                  Date
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Payment Method"
                v-model="edit.paymentMethod"
                :items="paymentExtendMethods"
                required
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" v-if="edit.customerDetails.type === 'Corporate'">
                <v-checkbox
                  v-model="edit.wtr"
                  color="primary"
                  required
                  label="Withholding Tax Request"
                >
                </v-checkbox>
              </v-col> -->
            <v-col cols="12">
              Monthly rate {{ compNewAutoDateEnd.monthlyRate }} x
              {{ compNewAutoDateEnd.months }} months
            </v-col>
            <v-col cols="12">
              Late Fee:
              {{ compNewAutoDateEnd.lateFee }}
            </v-col>
            <v-col cols="12">
              Total:
              {{ compNewAutoDateEnd.total }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="action === 'changeBilling'">
          <!-- EDIT -->
          <h3 class="text-center primary--text my-3">
            CHANGE BILLING DATE
          </h3>
          <v-form ref="formExtend" v-model="validChangeBilling" lazy-validation>
            <v-row class="mt-3">
              <v-col>
                <v-text-field
                  v-model="compOriginalDateEnd"
                  dense
                  disabled
                  label="End Date"
                  type="text"
                  hide-details="auto"
                  class="mb-2 mt-5"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-menu
                  v-model="dateEndMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="edit.dateEnd"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span style="color: #9c1b1f; font-weight=bold"
                          >NEW</span
                        >
                        End Date
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    :min="minChangeBillingDate"
                    :max="maxChangeBillingDate"
                    :allowed-dates="allowedDates"
                    v-model="edit.dateEnd"
                    @input="dateEndMenu = false"
                    :disabled="checkRole('dateEnd')"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Monthly Booking Amount"
                  v-model="compChangeBillingStandardMonthlyAmount"
                  dense
                  disabled
                  type="text"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Payment Method"
                  v-model="edit.paymentMethod"
                  :items="paymentMethods"
                ></v-select>
              </v-col>
              <!--  <v-col cols="12">
                            <v-text-field label="One Time Monthly Booking Amount" v-model="compChangeBillingMonthlyAmount" dense disabled type="text" hide-details="auto" ></v-text-field>
                          </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="One Time Fee"
                  v-model="compChangeBillingOneTimeFee"
                  dense
                  disabled
                  type="text"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showModal = false">
            Close
          </v-btn>
          <v-btn
            v-if="action === 'checkIn'"
            :disabled="validCheckin === false"
            color="primary"
            mt-5
            mx-auto
            @click="checkInBooking"
          >
            Yes, check in now
          </v-btn>
          <v-btn v-if="action === 'edit'" color="primary" @click="saveEdits()">
            Update Booking
          </v-btn>
          <v-btn
            v-if="action === 'extend' && booking.status !== 'Overdue'"
            :disabled="compExtendSubtotal === 0 || !edit.paymentMethod"
            color="primary"
            @click="saveExtend()"
          >
            Save
          </v-btn>
          <v-btn
            v-if="action === 'extend' && booking.status === 'Overdue'"
            :disabled="!edit.paymentMethod"
            color="primary"
            @click="saveAutoExtend()"
          >
            Save
          </v-btn>
          <v-btn
            v-if="action === 'changeBilling'"
            :disabled="compChangeBillingOneTimeFee === 0"
            color="primary"
            @click="saveChangeBilling()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
export default {
  data() {
    return {
      dev: null,
      dateEndMenu: false,
      action: null,
      validEditBooking: true,
      validExtendBooking: true,
      validChangeBilling: true,
      validCheckin: true,
      rules: {
        required: v => !!v || "Required",
        min: v => v.length >= 4 || "Min 4 characters",
        validBookingNo: v => v.length === 13 || "Invalid Booking No.",
        validID: v => v.length === 9 || v.length === 13 || "Invalid ID",
        alphanumeric: v => /^[a-zA-Z0-9]*$/.test(v) || "Alphanumeric only"
      },
      taxCodeRules: [
        v => !!v || "Required",
        v => v.length === 7 || v.length === 8 ||v.length === 9 || v.length === 13 || "Invalid ID",
        v => /^[a-zA-Z0-9]*$/.test(v) || "Alphanumeric only"
      ],
      edit: {
        customerID: null,
        status: null,
        notes: null,
        unitDetails: {
          unitNumber: null
        },
        dateEnd: null,
        amount: null,
        paymentMethod: null,
        isSubscription: null
      },
      paymentMethods: ["Bank Transfer", "Cash", "EDC"],
      paymentExtendMethods: ["Bank Transfer", "Cash", "EDC"],
      statusOptions: ["ID required", "Active", "Overdue", "Inactive"],
      extend: {
        weeks: null,
        months: null,
        sixMonths: null,
        years: null
      }
      // pricingData: []
    };
  },
  props: ["showEditBookingModal", "booking"],
  methods: {
    allowedDates(val) {
      // this function ensures forbidden end dates may not be selected
      const d = parseInt(val.split("-")[2]);
      const disallowed = [29, 30, 31];
      if (disallowed.indexOf(d) === -1) {
        return true;
      }
      return false;
    },
    decrement(durationType) {
      if (durationType === "week") {
        if (this.extend.weeks === 0 || this.extend.weeks === null) {
          this.extend.weeks = null;
        } else {
          this.extend.weeks = Number(this.extend.weeks) - 1;
        }
      }
      if (durationType === "month") {
        if (this.extend.months === 0 || this.extend.months === null) {
          this.extend.months = null;
        } else {
          this.extend.months = Number(this.extend.months) - 1;
        }
      }
      if (durationType === "sixmonth") {
        if (this.extend.sixMonths === 0 || this.extend.sixMonths === null) {
          this.extend.sixMonths = null;
        } else {
          this.extend.sixMonths = Number(this.extend.sixMonths) - 1;
        }
      }
      if (durationType === "year") {
        if (this.extend.years === 0 || this.extend.years === null) {
          this.extend.years = null;
        } else {
          this.extend.years = Number(this.extend.years) - 1;
        }
      }
    },
    increment(durationType) {
      if (durationType === "week") {
        if (this.extend.weeks === null) {
          this.extend.weeks = 1;
        } else {
          this.extend.weeks = Number(this.extend.weeks) + 1;
        }
      }
      if (durationType === "month") {
        if (this.extend.months === null) {
          this.extend.months = 1;
        } else {
          this.extend.months = Number(this.extend.months) + 1;
        }
      }
      if (durationType === "sixmonth") {
        if (this.extend.sixMonths === null) {
          this.extend.sixMonths = 1;
        } else {
          this.extend.sixMonths = Number(this.extend.sixMonths) + 1;
        }
      }
      if (durationType === "year") {
        if (this.extend.years === null) {
          this.extend.years = 1;
        } else {
          this.extend.years = Number(this.extend.years) + 1;
        }
      }
    },
    handleBlur() {
      if (this.edit.amount) {
        this.edit.amount = Number(
          this.edit.amount.replace(/[^\d.]/g, "")
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
    checkInBooking() {
      let vm = this;
      let booking = vm.booking;
      // console.log(vm.validCheckin);
      if (this.$refs.formCheckin.validate()) {
        // SEND ADMIN 'CHECK IN' TO API
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .put(vm.$root.urls.api + "/v3/checkInBooking", { booking })
          .then(function(response) {
            // handle success
            let updatedBooking = response.data;
            booking.status = "Active";
            booking.transactions = updatedBooking.transactions;
            vm.$store.commit("updateBooking", booking);

            // close modal
            vm.showModal = false;
          })
          .catch(function(error) {
            // handle error
            console.log(error);
          })
          .then(function() {
            // always executed
          });
      } else {
        console.log("invalid");
      }
    },
    checkOut() {
      let vm = this;
      let booking = vm.booking;
      booking.status = "Inactive";
      // SEND ADMIN 'CHECK IN' TO API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/v3/checkOutBooking", { booking })
        .then(function(response) {
          // handle success
          let updatedBooking = response.data;
          vm.$store.commit("updateBooking", updatedBooking);

          // send checkout email
          axios.post(vm.$root.urls.api + "/v3/checkOutBooking", { booking });

          // close modal
          vm.showModal = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    cancelBooking() {
      let vm = this;
      let booking = vm.booking;
      // SEND ADMIN 'CHECK IN' TO API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/v3/adminCancelBooking", { booking })
        .then(function(response) {
          // handle success
          let updatedBooking = response.data;
          vm.$store.commit("updateBooking", updatedBooking);

          // show friendly message

          // close modal
          vm.showModal = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    editBooking() {
      this.action = "edit";
      this.edit = structuredClone(this.booking);
      this.edit.dateEnd = moment(this.booking.dateEnd).format("YYYY-MM-DD");
    },
    saveEdits() {
      let vm = this;
      let editedBooking = structuredClone(vm.edit);
      let originalBookingDateEnd = moment(vm.booking.dateEnd).format(
        "YYYY-MM-DD"
      );

      // add the original booking dateEnd to the booking data
      editedBooking.originalBookingDateEnd = originalBookingDateEnd;

      // sometimes the admin forgets to change te status of an overdue booking
      if (vm.booking.status === "Overdue") {
        console.log(
          `not equal: ${originalBookingDateEnd} ${editedBooking.dateEnd}`
        );
        console.log(
          `same or after: ${editedBooking.dateEnd} ${moment().format(
            "YYYY-MM-DD"
          )}`
        );
        if (
          originalBookingDateEnd !== editedBooking.dateEnd &&
          moment(editedBooking.dateEnd).isSameOrAfter(
            moment().format("YYYY-MM-DD")
          )
        ) {
          editedBooking.status = "Active";
        }
      }

      // SEND ADMIN EDITED BOOKING VALUES TO API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/v3/adminEditBooking", { editedBooking })
        .then(function(response) {
          // handle success
          let updatedBooking = response.data;
          vm.$store.commit("updateBooking", updatedBooking);
          // close modal
          vm.showModal = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    extendBooking() {
      this.action = "extend";
      this.edit = structuredClone(this.booking);
      this.edit.dateEnd = moment(this.booking.dateEnd).format("YYYY-MM-DD");
      // flag this as an extend rather than a simple admin edit (used in api when creating a transaction)
      this.edit.action = "extend";
    },
    saveExtend() {
      console.log(this.edit);
      // prepare updated booking data

      let vm = this;
      let editedBooking = structuredClone(vm.edit);
      let originalBookingDateEnd = moment(vm.booking.dateEnd).format(
        "YYYY-MM-DD"
      );

      // add the original booking dateEnd to the booking data
      editedBooking.originalBookingDateEnd = originalBookingDateEnd;

      // add the extended booking dateEnd to the booking data
      editedBooking.dateEnd = vm.compNewDateEnd;

      // sometimes the admin forgets to change te status of an overdue booking
      if (vm.booking.status === "Overdue") {
        console.log(
          `not equal: ${originalBookingDateEnd} ${editedBooking.dateEnd}`
        );
        console.log(
          `same or after: ${editedBooking.dateEnd} ${moment().format(
            "YYYY-MM-DD"
          )}`
        );
        if (
          originalBookingDateEnd !== editedBooking.dateEnd &&
          moment(editedBooking.dateEnd).isSameOrAfter(
            moment().format("YYYY-MM-DD")
          )
        ) {
          editedBooking.status = "Active";
        }
      }

      // add subtotal
      editedBooking.amount = vm.compExtendSubtotal;

      // SEND ADMIN EDITED BOOKING VALUES TO API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/v3/adminEditBooking", { editedBooking })
        .then(function(response) {
          // handle success
          let updatedBooking = response.data;
          vm.$store.commit("updateBooking", updatedBooking);
          // close modal
          vm.showModal = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    saveAutoExtend() {
      // CREATE A PAYMENT TRANSACTION IF THIS HAS A PAYMENT METHOD OTHER THAN PAYMENT LINK
      if (this.edit.paymentMethod !== "Payment Link") {
        // prepare updated booking data

        let vm = this;
        let editedBooking = structuredClone(vm.edit);
        let originalBookingDateEnd = moment(vm.booking.dateEnd).format(
          "YYYY-MM-DD"
        );

        // add the original booking dateEnd to the booking data
        editedBooking.originalBookingDateEnd = originalBookingDateEnd;

        // add the extended booking dateEnd to the booking data
        editedBooking.dateEnd = vm.compNewAutoDateEnd.newEndDate;

        // sometimes the admin forgets to change te status of an overdue booking
        editedBooking.status = "Active";

        // add subtotal
        editedBooking.amount = vm.compNewAutoDateEnd.total;

        // SEND ADMIN EDITED BOOKING VALUES TO API
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .put(vm.$root.urls.api + "/v3/adminEditBooking", { editedBooking })
          .then(function(response) {
            // handle success
            let updatedBooking = response.data;
            vm.$store.commit("updateBooking", updatedBooking);
            // close modal
            vm.showModal = false;
          })
          .catch(function(error) {
            // handle error
            console.log(error);
          })
          .then(function() {
            // always executed
          });

        console.log("create transaction");
      } else {
        console.log("create payment link");

        let vm = this;
        let editedBooking = structuredClone(vm.edit);
        let originalBookingDateEnd = moment(vm.booking.dateEnd).format(
          "YYYY-MM-DD"
        );

        let paylink = {
          customer: editedBooking.customerDetails._id,
          bookingNumber: editedBooking.confirmation,
          lineItems: [
            {
              id: moment().unix(),
              service: "Storage service",
              quantity: 1,
              price: vm.compNewAutoDateEnd.total,
              notes: "Payment Link generated via admin auto extend"
            }
          ],
          email: editedBooking.customerDetails.username,
          firstname: editedBooking.customerDetails.firstname,
          lastname: editedBooking.customerDetails.lastname
        };
        console.log(paylink);
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        // save new paylink via API
        axios
          .post(vm.$root.urls.api + "/v3/paymentLink", {
            paylink
          })
          .then(function(results) {
            // toast
            vm.$toasted.show("Paylink added").goAway(2500);
          })
          .catch(function(error) {
            console.log(error);
            // toast
            vm.$toasted.show("Unable to add paylink").goAway(2500);
          });
      }
      this.showModal = false;
    },
    changeBilling() {
      this.action = "changeBilling";
      this.edit = structuredClone(this.booking);
      this.edit.dateEnd = moment(this.booking.dateEnd).format("YYYY-MM-DD");
    },
    saveChangeBilling() {
      let vm = this;
      let editedBooking = structuredClone(vm.edit);
      let originalBookingDateEnd = moment(vm.booking.dateEnd).format(
        "YYYY-MM-DD"
      );

      // add the original booking dateEnd to the booking data
      editedBooking.originalBookingDateEnd = originalBookingDateEnd;

      // add the payment amount to the booking data
      editedBooking.amount = vm.compChangeBillingOneTimeFee;

      // add the calculated one time billing amount to billingDateOverrideAmount
      // editedBooking.billingDateOverrideAmount = vm.compChangeBillingMonthlyAmount

      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/v3/changeBillingDate", { editedBooking })
        .then(function(response) {
          // handle success
          let updatedBooking = response.data;
          vm.$store.commit("updateBooking", updatedBooking);
          // close modal
          vm.showModal = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    parseCompanyName(companyName) {
      if (companyName) {
        return ` (${companyName})`;
      }
      return "";
    },
    checkRole(input) {
      // THIS METHOD EVALUATES INPUTS WITH THE USER ROLE. IF THE USER SHOULD NOT USE THIS INPUT, IT IS DISABLED (RETURN TRUE)
      if (input === "status" && this.role === "Chief In training") {
        return true;
      }
      if (input === "status" && this.role === "Chief") {
        return true;
      }
      if (input === "dateEnd" && this.role === "Chief In training") {
        return true;
      }
      if (input === "paymentAmount" && this.role !== "Admin") {
        return true;
      }
      return false;
    }
    /* getPricing() {
      //v3 get unit pricing data
      let vm = this;
      return axios
        .get(vm.$root.urls.api + "/v3/prices", {})
        .then(function(response) {
          var pricing = response.data;
          vm.pricingData = pricing;
        });
    } */
  },
  computed: {
    compTaxCode: {
      get: function() {
        return this.booking.customerDetails.taxCode
          ? this.booking.customerDetails.taxCode
          : "";
      },
      set: function(newValue) {
        this.booking.customerDetails.taxCode = newValue;
      }
    },
    pricingData() {
      return this.$store.state.pricing;
    },
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    showModal: {
      get: function() {
        return this.showEditBookingModal;
      },
      set: function(value) {
        // reset modal
        this.action = null;
        this.$emit("closeShowEditBookingModal", value);
      }
    },
    compUsers() {
      let users = this.$store.state.users;
      users.map(
        u =>
          (u.fullname =
            u.firstname +
            " " +
            u.lastname +
            this.parseCompanyName(u.companyName))
      );
      return users;
    },
    compShowCancelBookingButton() {
      if (this.role === "Chief In training") {
        return false;
      }
      if (
        this.booking.status === "ID required" ||
        this.booking.status === "Active"
      ) {
        return true;
      }
      return false;
    },
    compNewDateEnd() {
      // IF A BOOKING IS BEING EXTENDED, WE ADD THE ADDITIONAL DURATIONS TO THE BOOKING END DATE
      if (this.edit.dateEnd) {
        let dateEnd = moment(this.edit.dateEnd);

        // add additional weeks, if any have been selected
        dateEnd = dateEnd.add(this.extend.weeks, "weeks");
        // add additional months, if any have been selected
        dateEnd = dateEnd.add(this.extend.months, "months");
        // add additional six months, if any have been selected
        dateEnd = dateEnd.add(Number(this.extend.sixMonths) * 6, "months");
        // add additional years, if any have been selected
        dateEnd = dateEnd.add(this.extend.years, "years");

        // if the date is the 29th, 30th, or 31st, we need to set it to the first day of the next month
        if (dateEnd.date() > 28) {
          dateEnd = dateEnd.add(1, "months").date(1);
        }

        return dateEnd.format("YYYY-MM-DD");
      }
      return "";
    },
    compNewAutoDateEnd() {
      if (this.edit.dateEnd) {
        const pastDate = moment(this.edit.dateEnd); // replace with your actual past date
        const currentDate = moment(); // gets the current date

        // Calculate the difference in months
        let monthsToAdd = currentDate.diff(pastDate, "months");

        // If the current date is already later than the past date, add 1 to make it a future date
        if (monthsToAdd >= 0) {
          monthsToAdd += 1;
        }

        const newAutoDateEnd = moment(this.edit.dateEnd)
          .add(monthsToAdd, "months")
          .format("YYYY-MM-DD");

        // const lateFee = 50;

        let lateFee = 50;

        if (monthsToAdd <= 1) {
          lateFee = 50;
        } else {
          // console.log(`months overdue: ${monthsToAdd}`)
          // if overdue one or more months, overdue fee is 50 + numMonths * (100 + interest rate of 1.25%)
          lateFee = 50;
          lateFee =
            lateFee +
            (monthsToAdd - 1) *
              (100 + Number(this.compPricingData.pricePerMonth) * 0.0125);
          lateFee = Math.ceil(lateFee);
        }

        return {
          newEndDate: newAutoDateEnd,
          monthlyRate: Number(this.compPricingData.pricePerMonth),
          months: monthsToAdd,
          lateFee: lateFee,
          total:
            Number(this.compPricingData.pricePerMonth) * Number(monthsToAdd) +
            lateFee
        };
      }
    },
    compExtendSubtotal() {
      if (this.compPricingData) {
        let subtotal = 0;

        // add number of extended weeks
        if (this.extend.weeks !== null) {
          subtotal =
            subtotal +
            Number(this.compPricingData.pricePerWeek) *
              Number(this.extend.weeks);
        }

        // add number of extended months
        if (this.extend.months !== null) {
          subtotal =
            subtotal +
            Number(this.compPricingData.pricePerMonth) *
              Number(this.extend.months);
        }

        // add number of extended six months
        if (this.extend.sixMonths !== null) {
          subtotal =
            subtotal +
            Number(this.compPricingData.pricePerHalfYear) *
              Number(this.extend.sixMonths);
        }

        // add number of extended years
        if (this.extend.years !== null) {
          subtotal =
            subtotal +
            Number(this.compPricingData.pricePerYear) *
              Number(this.extend.years);
        }

        return subtotal;
      } else {
        return 0;
      }
    },
    compExtendLatePaymentFee() {
      //   if (this.edit.status === 'Overdue') {

      //       // calculate monthly rate via price data
      //       const monthlyRate = this.compPricingData.pricePerMonth

      //       // if overdue less than one month, overdue fee is 50
      //       // const startDate = this.edit.dateStart
      //       // const endDate = new moment()
      //       // const monthsDiff = endDate.diff(startDate, 'months');
      //       // if (monthsDiff < 1) {
      //       //   return 50
      //       // } else {
      //         console.log(`months overdue: ${this.extend.months}`)
      //         // if overdue one or more months, overdue fee is 50 + numMonths * (100 + interest rate of 1.25%)
      //         let subtotal = 50;
      //         subtotal = subtotal + this.extend.months * (100 + (monthlyRate * .0125))
      //         subtotal = Math.ceil(subtotal)
      //         return subtotal
      //       // }
      // }
      return 0;
    },
    compPricingData() {
      let data = this.pricingData;

      // filter on location
      if (this.edit.unitDetails.location) {
        let results = data.filter(
          d => d.location === this.edit.unitDetails.location
        );
        data = results;
      }

      // filter on type
      if (this.edit.unitDetails.type) {
        let results = data.filter(d => d.type === this.edit.unitDetails.type);
        data = results;
      }

      return data[0];
    },
    compExtendTotal() {
      if (this.compExtendSubtotal) {
        return (
          Number(this.compExtendSubtotal) -
          Number(this.edit.customerDetails.credit) +
          Number(this.compExtendLatePaymentFee)
        );
      } else {
        return 0;
      }
    },
    compOriginalDateEnd() {
      if (this.booking.dateEnd)
        return moment(this.booking.dateEnd).format("YYYY-MM-DD");
    },
    compChangeBillingStandardMonthlyAmount() {
      // if the booking has a subscription price, return that
      if (this.booking.subscriptionPrice) {
        return this.booking.subscriptionPrice;
      }
      return this.compPricingData.priceSubscription;
    },
    compChangeBillingMonthlyAmount() {
      if (
        this.booking.dateEnd &&
        this.compPricingData &&
        !moment(this.booking.dateEnd).isSame(moment(this.edit.dateEnd), "day")
      ) {
        // calculate number of days to add
        let numDays = moment(this.edit.dateEnd)
          .startOf("day")
          .diff(moment(this.booking.dateEnd).startOf("day"), "days");

        // get monthly susbscription amount
        let monthlyRate = this.compPricingData.priceSubscription;

        // if the booking has a subscription price, use that instead
        if (this.booking.subscriptionPrice) {
          monthlyRate = Number(this.booking.subscriptionPrice);
        }

        // calculate daily rate
        let dailyRate = Math.ceil(monthlyRate / 30);

        // debugging tool
        console.log(
          `monthly rate: ${monthlyRate} daily rate: ${dailyRate} number of days: ${numDays} `
        );

        // return the monthly rate plus the number of days * daily rate
        return monthlyRate + dailyRate * numDays;
      }

      // if the booking has a subscription price, return that
      if (this.booking.subscriptionPrice) {
        return Number(this.booking.subscriptionPrice);
      }
      return this.compPricingData.priceSubscription;
    },
    compChangeBillingOneTimeFee() {
      if (
        this.compChangeBillingStandardMonthlyAmount &&
        this.compChangeBillingMonthlyAmount
      ) {
        return (
          Number(this.compChangeBillingMonthlyAmount) -
          Number(this.compChangeBillingStandardMonthlyAmount)
        );
      }
      return 0;
    },
    minChangeBillingDate() {
      if (this.booking.dateEnd) {
        return this.booking.dateEnd;
      } else {
        return new moment().format("YYYY-MM-DD");
      }
    },
    maxChangeBillingDate() {
      if (this.booking.dateEnd) {
        return moment(this.booking.dateEnd)
          .add(1, "month")
          .format("YYYY-MM-DD");
      } else {
        // return new moment().format("YYYY-MM-DD")
        return new moment().add(1, "month").format("YYYY-MM-DD");
      }
    }
  },
  mounted: function() {
    // this.getPricing();
  },
  watch: {
    showEditBookingModal(val) {
      if (val) {
        // reset extend durations
        this.extend = {
          weeks: null,
          months: null,
          sixMonths: null,
          years: null
        };
      }
    },
    edit: {
      handler(val) {
        if (val.isSubscription) {
          // console.log(`ok its a subscription boys`)
          // console.log(this.edit.subscriptionPrice)
          if (
            typeof this.edit.subscriptionPrice === "undefined" ||
            this.edit.subscriptionPrice === ""
          ) {
            this.edit.subscriptionPrice = this.compChangeBillingStandardMonthlyAmount;
          }
        } else {
          // console.log(`nah leave it`)
        }
      },
      deep: true
    }
  }
};
</script>

<template>
  <div>
    <div v-if="editor" style="margin-bottom: 20px;">
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :disabled="
          !editor
            .can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :disabled="
          !editor
            .can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 1 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 2 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 3 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 4 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        <v-icon>mdi-format-header-4</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 5 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        <v-icon>mdi-format-header-5</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        type="button"
        @click="
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 6 })
            .run()
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        <v-icon>mdi-format-header-6</v-icon>
      </v-btn>
    </div>

    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

export default {
  components: {
    EditorContent
  },

  props: {
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      editor: null
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    }
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit],
      onUpdate: () => {
        // HTML
        this.$emit("updateContent", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  border: 1px solid #949494;
}
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toasted from "vue-toasted";

import LogRocket from "logrocket";
if (process.env.VUE_APP_API !== "http://localhost:3000") {
  LogRocket.init("redd/booking-admin");
}

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false;
Vue.use(Toasted, {
  iconPack: "mdi"
});


new Vue({
  computed: {
    urls() {
      let obj = {
        api: process.env.VUE_APP_API
      };
      console.log(obj.api);
      return obj;
    }
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

<template>
  <v-navigation-drawer id="sidenav" permanent expand-on-hover elevation="2">
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="@/assets/images/r-logo.svg"></v-img>
        </v-list-item-avatar>
        <v-list-item-title
          ><v-img
            src="@/assets/images/redd-text-logo.svg"
            max-height="30"
            max-width="140"
          ></v-img
        ></v-list-item-title>
      </v-list-item>

      <!-- <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="title">
                    Sandra Adams
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >sandra_a88@gmail.com</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item> -->
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link to="/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/bookings">
        <v-list-item-icon>
          <v-icon>mdi-calendar-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Bookings</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/units">
        <v-list-item-icon>
          <v-icon>mdi-locker</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Units</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/paylinks">
        <v-list-item-icon>
          <v-icon>mdi-link-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Payment Links</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/vouchers">
        <v-list-item-icon>
          <v-icon>mdi-cash-100</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Vouchers</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/profile">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>My Profile</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/users">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title>User Management</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/activity">
        <v-list-item-icon>
          <v-icon>mdi-fingerprint</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Activity Log</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/reports">
        <v-list-item-icon>
          <v-icon>mdi-chart-timeline-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Reports</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="environment === 'DEVELOPMENT'" link to="/testing">
        <v-list-item-icon>
          <v-icon>mdi-wrench</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Testing</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideNav",

  data: () => ({
    // notifications: 2
  }),
  computed: {
    role() {
      return this.$store.state.user.role;
    },
    notifications() {
      return this.$store.state.notifications;
    },
    bulletins() {
      // return this.$store.state.bulletins.length;
      return 0;
    },
    environment() {
      return process.env.VUE_APP_ENVIRONMENT;
    }
  }
};
</script>

<style lang="scss">
#sidenav {
  z-index: 100;
  position: fixed;
  top: 0px;
}
</style>

<template>
  <div class="text-center">
    <v-dialog v-model="showModal" width="500" persistent>
      <v-card v-if="unit">
        <v-card-title class="text-h5 primary white--text">
          {{ unit.unitNumber }}
        </v-card-title>
        <v-card-text>
          <!-- EDIT -->
          <h3 class="text-center primary--text my-3">
            EDIT UNIT
          </h3>
          <v-form ref="form" v-model="validEditUnit" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Unit Number"
                  v-model="edit.unitNumber"
                ></v-text-field>
              </v-col>
              <v-col v-if="compShowEnabled" cols="6">
                <v-switch v-model="edit.enabled" label="Enabled?"></v-switch>
              </v-col>
            </v-row>

            <v-text-field
              v-if="edit.confirmation"
              disabled
              label="Booking Confirmation No."
              v-model="edit.confirmation"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="edit.unitStatus"
                  :items="statusOptions"
                  label="Status"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Type"
                  v-model="edit.type"
                  :disabled="checkRole('type')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field label="Floor" v-model="edit.floor"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Zone" v-model="edit.zone"></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-textarea
                          label="Booking Notes"
                          v-model="edit.notes"
                          hint="Booking notes, comments, etc."
                        ></v-textarea> -->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="showConfirmDelete === false">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showModal = false">
            Close
          </v-btn>
          <!--  <v-btn color="primary" outlined @click="showConfirmDelete = true">
                        Delete Unit
                    </v-btn> -->
          <v-btn color="primary" @click="saveEdits()">
            Update Unit
          </v-btn>
        </v-card-actions>
        <!-- <div v-if="showConfirmDelete" class="text-center">You are about to delete this unit. Do you wish to proceed?
                <v-card-actions >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showConfirmDelete = false">
                        Cancel
                    </v-btn>
                        <v-btn color="primary" outlined @click="deleteUnit()">
                        Delete Unit
                    </v-btn>
                </v-card-actions>
                </div> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
export default {
  data() {
    return {
      validEditUnit: true,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 4 || "Min 4 characters"
      },
      edit: {
        unitStatus: null,
        confirmation: null
      },
      statusOptions: ["Available", "Unavailable", "Booked"],
      showConfirmDelete: false
    };
  },
  props: ["showEditUnitModal", "unit"],
  methods: {
    saveEdits() {
      let vm = this;
      let unit = vm.unit;
      let editedUnit = vm.edit;

      // Permissions:

      let editIsAllowed = true;

      // HEAD role permissions:
      if (this.role === "Head") {
        if (
          unit.unitStatus === "Available" &&
          editedUnit.unitStatus === "Booked"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Booked" &&
          editedUnit.unitStatus === "Unavailable"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Booked" &&
          editedUnit.unitStatus === "Available"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Unavailable" &&
          editedUnit.unitStatus === "Booked"
        )
          editIsAllowed = false;
      }

      // CHIEF role permissions:
      if (this.role === "Chief") {
        if (
          unit.unitStatus === "Available" &&
          editedUnit.unitStatus === "Booked"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Booked" &&
          editedUnit.unitStatus === "Unavailable"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Booked" &&
          editedUnit.unitStatus === "Available"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Unavailable" &&
          editedUnit.unitStatus === "Booked"
        )
          editIsAllowed = false;
      }

      // CHIEF IN TRAINING role permissions:
      if (this.role === "Chief In training") {
        if (
          unit.unitStatus === "Available" &&
          editedUnit.unitStatus === "Booked"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Booked" &&
          editedUnit.unitStatus === "Unavailable"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Booked" &&
          editedUnit.unitStatus === "Available"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Unavailable" &&
          editedUnit.unitStatus === "Booked"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Available" &&
          editedUnit.unitStatus === "Unavailable"
        )
          editIsAllowed = false;
        if (
          unit.unitStatus === "Unavailable" &&
          editedUnit.unitStatus === "Available"
        )
          editIsAllowed = false;
      }

      if (editIsAllowed) {
        // SEND ADMIN EDITED BOOKING VALUES TO API
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .put(vm.$root.urls.api + "/v3/adminEditUnit", { unit, editedUnit })
          .then(function(response) {
            // handle success
            let updatedUnit = response.data;
            vm.$store.commit("updateUnit", updatedUnit);

            // show friendly message

            // close modal
            vm.showModal = false;
          })
          .catch(function(error) {
            // handle error
            console.log(error);
          })
          .then(function() {
            // always executed
          });
      } else {
        vm.$toasted
          .show("Sorry, you do not have permission to make this edit.", {
            type: "error",
            icon: "mdi-alert-circle"
          })
          .goAway(2500);
      }
    },
    checkRole(input) {
      // THIS METHOD EVALUATES INPUTS WITH THE USER ROLE. IF THE USER SHOULD NOT USE THIS INPUT, IT IS DISABLED (RETURN TRUE)
      // if (input==='status') {
      //     if(this.role==='Admin') return false;
      //     if(this.role==='Head' && this.edit.unitStatus==='Available')
      // }
      // if (input==='status' && this.role === 'Chief In training' ) {
      //     return true;
      // }
      // if (input==='status' && this.role === 'Chief' ) {
      //     return true;
      // }
      // if (input==='dateEnd' && this.role === 'Chief In training' ) {
      //     return true;
      // }
      if (
        input === "type" &&
        (this.role === "Chief" || this.role === "Chief In training")
      ) {
        return true;
      }
      return false;
    }
    // deleteUnit() {
    //     let vm = this;
    //     // let unit = vm.unit;
    //     let deletedUnit = vm.edit;

    //     console.log(deletedUnit)

    //     // only units with a status of 'Available' may be deleted

    //     if(deletedUnit.unitStatus==='Available') {

    //         // SEND ADMIN EDITED BOOKING VALUES TO API
    //         axios.defaults.headers.common = {
    //             Authorization: `Bearer ${vm.token}`,
    //         };
    //         axios.delete(vm.$root.urls.api + '/v3/adminEditUnit', { data: deletedUnit })
    //             .then(function(response) {
    //                 // handle success
    //                 vm.$store.commit("deleteUnit",
    //                     deletedUnit
    //                 )
    //                 // close modal
    //                 vm.showConfirmDelete = false
    //                 vm.showModal = false;
    //             })
    //             .catch(function(error) {
    //                 // handle error
    //                 console.log(error);
    //             })
    //             .then(function() {
    //                 // always executed
    //             });

    //     } else {
    //         vm.$toasted
    //                 .show(
    //                   "Unit is not 'Available' and may not be deleted. ", {
    //                     type: "error",
    //                     icon: "mdi-thumb-down"
    //                   }
    //                 )
    //                 .goAway(2500);
    //     }

    // }
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    showModal: {
      get: function() {
        return this.showEditUnitModal;
      },
      set: function(value) {
        this.edit = {
          unitStatus: null,
          confirmation: null
        };
        // reset modal
        this.$emit("closeShowEditUnitModal", value);
      }
    },
    compStatus() {
      if (this.role === "Admin") {
        return ["Available", "Unavailable", "Booked"];
      }
      if (this.role === "Admin") {
        return ["Available", "Unavailable", "Booked"];
      }
      // Chief In training cant change status as its disabled, so we can show all values
      if (this.role === "Chief In training") {
        return ["Available", "Unavailable", "Booked"];
      }
    },
    compShowEnabled() {
      if (this.role === "Admin" && (this.edit.unitStatus === "Available" || this.edit.unitStatus === "Unavailable") ) {
        return true;
    }
    return false;
    }
  },
  watch: {
    unit(val) {
      if (val) {
        this.edit = structuredClone(val);
      }
    }
  }
  // mounted() {
  //     if(this.unit) {
  //         this.edit = this.unit
  //     }
  // }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.editor)?_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"x-small":"","text":"","type":"button","disabled":!_vm.editor
          .can()
          .chain()
          .focus()
          .toggleBold()
          .run()},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-bold")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"x-small":"","text":"","type":"button","disabled":!_vm.editor
          .can()
          .chain()
          .focus()
          .toggleItalic()
          .run()},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-italic")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 1 }) },attrs:{"x-small":"","text":"","type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 1 })
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-1")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"x-small":"","text":"","type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-2")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 3 }) },attrs:{"x-small":"","text":"","type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 3 })
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-3")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 4 }) },attrs:{"x-small":"","text":"","type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 4 })
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-4")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 5 }) },attrs:{"x-small":"","text":"","type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 5 })
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-5")])],1),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 6 }) },attrs:{"x-small":"","text":"","type":"button"},on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 6 })
          .run()}}},[_c('v-icon',[_vm._v("mdi-format-header-6")])],1)],1):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
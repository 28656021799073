<template>
  <div class="text-center">
    <v-dialog v-model="showModal" width="500" persistent>
      <v-card v-if="pricing">
        <v-card-title class="text-h5 primary white--text">
          EDIT PRICING
        </v-card-title>
        <v-card-text>
          <!-- EDIT -->
          <!-- <h3 class="text-center primary--text my-3">
                        EDIT PRICING
                    </h3> -->
          <v-form ref="form" v-model="validEditPricing" lazy-validation>
            <!-- <v-text-field label="Location" v-model="edit.location"></v-text-field>
                        <v-text-field label="Type" v-model="edit.type"></v-text-field>
                        <v-text-field label="Type" v-model="edit.type"></v-text-field> -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="unitLocations"
                  v-model="edit.location"
                  label="Location"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="edit.type" label="Type"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.pricePerWeek"
                  label="Price per week"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.pricePerMonth"
                  label="Price per month"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.pricePerHalfYear"
                  label="Price per half year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.pricePerYear"
                  label="Price per year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.priceSubscription"
                  label="Subscription Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.parent"
                  label="Parent"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.width"
                  :rules="[rules.numeric]"
                  label="Unit Width"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.length"
                  :rules="[rules.numeric]"
                  label="Unit Length"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.height"
                  :rules="[rules.numeric]"
                  label="Unit Height"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.weightLimit"
                  :rules="[rules.numeric]"
                  label="Weight Limit"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.doorWidth"
                  :rules="[rules.numeric]"
                  label="Door Width"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="edit.doorHeight"
                  :rules="[rules.numeric]"
                  label="Door Height"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <!-- <v-textarea label="Description" v-model="edit.description"></v-textarea> -->
                <label>Description</label>
                <hr
                  style="height: 0px; border: none; border-bottom: 1px solid #949494; padding: 3px 0px; margin-bottom: 10px;"
                />
                <tiptap
                  :value="edit.description"
                  @updateContent="updatePriceContent"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="showConfirmDelete === false">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showModal = false">
            Close
          </v-btn>
          <!-- <v-btn color="primary" outlined @click="showConfirmDelete = true">
                        Delete Price
                    </v-btn> -->
          <v-btn color="primary" @click="saveEdits()">
            Update Price
          </v-btn>
        </v-card-actions>
        <!--  <div v-if="showConfirmDelete" class="text-center">You are about to delete this price. Do you wish to proceed?
                <v-card-actions >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showConfirmDelete = false">
                        Cancel
                    </v-btn>
                        <v-btn color="primary" outlined @click="deletePrice()">
                        Delete Price
                    </v-btn>
                </v-card-actions>
                </div> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import Tiptap from "@/components/Tiptap.vue";
export default {
  components: {
    Tiptap
  },
  data() {
    return {
      validEditPricing: true,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 4 || "Min 4 characters",
        numeric: v => !isNaN(parseFloat(v)) || "Numeric only"
      },
      edit: {
        unitStatus: null,
        confirmation: null
      },
      statusOptions: ["Available", "Unavailable", "Booked"],
      showConfirmDelete: false,
      unitLocations: ["RAMA 9", "LKB", "LCB"]
    };
  },
  props: ["showEditPricingModal", "pricing"],
  methods: {
    saveEdits() {
      // console.log('Price saved')
      let vm = this;
      let price = vm.edit;
      // SEND ADMIN EDITED BOOKING VALUES TO API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/v3/prices", { price })
        .then(function(response) {
          // handle success
          let updatedPrice = response.data;
          // vm.$store.commit("updateUnit",
          //     updatedUnit
          // )

          // show friendly message

          // close modal
          vm.showModal = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
    // deleteUnit() {
    //     let vm = this;
    //     // let unit = vm.unit;
    //     let deletedUnit = vm.edit;

    //     console.log(deletedUnit)

    //     // only units with a status of 'Available' may be deleted

    //     if(deletedUnit.unitStatus==='Available') {

    //         // SEND ADMIN EDITED BOOKING VALUES TO API
    //         axios.defaults.headers.common = {
    //             Authorization: `Bearer ${vm.token}`,
    //         };
    //         axios.delete(vm.$root.urls.api + '/v3/adminEditUnit', { data: deletedUnit })
    //             .then(function(response) {
    //                 // handle success
    //                 vm.$store.commit("deleteUnit",
    //                     deletedUnit
    //                 )
    //                 // close modal
    //                 vm.showConfirmDelete = false
    //                 vm.showModal = false;
    //             })
    //             .catch(function(error) {
    //                 // handle error
    //                 console.log(error);
    //             })
    //             .then(function() {
    //                 // always executed
    //             });

    //     } else {
    //         vm.$toasted
    //                 .show(
    //                   "Unit is not 'Available' and may not be deleted. ", {
    //                     type: "error",
    //                     icon: "mdi-thumb-down"
    //                   }
    //                 )
    //                 .goAway(2500);
    //     }

    // },
    updatePriceContent(content) {
      this.edit.description = content;
    }
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    showModal: {
      get: function() {
        return this.showEditPricingModal;
      },
      set: function(value) {
        this.edit = {
          unitStatus: null,
          confirmation: null
        };
        // reset modal
        this.$emit("closeShowEditPricingModal", value);
      }
    }
  },
  watch: {
    pricing(val) {
      if (val) {
        this.edit = val;
      }
    }
  }
  // mounted() {
  //     if(this.unit) {
  //         this.edit = this.unit
  //     }
  // }
};
</script>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function milk() {
  return "best drink";
}

// async function getCustomerByID(id, index) {
//   let customers = state.customers
//   const customer = customers.find( c => c._id === id)
//   // const unit = await Unit.findOne(
//   //   { _id: id },
//   //   { bookings: 0, isRecurring: 0, recurringPrice: 0, unitNumberIndex: 0 }
//   // );
//   // return [unit, index];
// }

export default new Vuex.Store({
  state: {
    user: {
      username: "",
      role: "",
      firstname: "",
      lastname: "",
      token: "",
      phone: "",
      notification: "",
      unread: [],
      darkMode: false
    },
    users: [],
    bookings: [],
    units: [],
    pricing: [],
    paymentLinks: []
  },
  mutations: {
    login(state, payload) {
      state.user.username = payload.username;
      state.user.role = payload.role;
      state.user.firstname = payload.firstname;
      state.user.lastname = payload.lastname;
      state.user.token = payload.token;
      state.user.phone = payload.phone;
      state.user.notification = payload.notification;
      state.user.unread = payload.unread;
      state.user.darkMode = payload.darkMode;
    },
    updateProfile(state, payload) {
      state.user.firstname = payload.firstname;
      state.user.lastname = payload.lastname;
      state.user.phone = payload.phone;
      state.user.notification = payload.notification;
      state.user.darkMode = payload.darkMode;
    },
    setBookings(state, payload) {
      state.bookings = payload.bookings;
    },
    setUnits(state, payload) {
      state.units = payload.units;
    },
    setUsers(state, payload) {
      state.users = payload.users;
    },
    setPricing(state, payload) {
      state.pricing = payload.pricing;
    },
    setPaymentLinks(state, payload) {
      state.paymentLinks = payload.paymentLinks;
    },

    updatePricing(state, payload) {
      let data = state.pricing;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload._id) {
          Vue.set(state.pricing, i, payload);
        }
      }
    },
    addPricing(state, payload) {
      state.pricing.push(payload);
    },
    updateBooking(state, payload) {
      let data = state.bookings;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload._id) {
          state.bookings.splice(i, 1);
          state.bookings.push(payload);
          // Vue.set(state.bookings, i, payload);
        }
      }
    },
    updateUnit(state, payload) {
      let data = state.units;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload._id) {
          state.units.splice(i, 1);
          state.units.push(payload);
        }
      }
    },
    deleteUnit(state, payload) {
      let data = state.units;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload._id) {
          state.units.splice(i, 1);
        }
      }
    },
    reserveUnit(state, payload) {
      let data = state.units;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload._id) {
          let unit = data[i];
          unit.unitStatus = payload.unitStatus;
          state.units.splice(i, 1);
          state.units.push(unit);
        }
      }
    },
    addUnit(state, payload) {
      state.units.push(payload);
    },
    addBooking(state, payload) {
      // get unitDetails
      let data = state.units;
      let unitDetails = data.filter(d => d._id === payload.unitID);
      let booking = payload;
      booking.unitDetails = unitDetails[0];
      state.bookings.push(booking);
    }
    /* saveDocuments(state, payload) {
      state.documents = payload.documents;
    },
    updateDocument(state, payload) {
      let data = state.documents;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.document._id) {
          data[i].isFavorite = payload.document.isFavorite;
        }
      }
    },
    deleteDocument(state, payload) {
      // console.log(state.documents.length);
      let data = state.documents;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.document._id) {
          state.documents.splice(i, 1);
        }
      }
    },
    addActivity(state, payload) {
      state.activities.push(payload.activity);
    }, */
    /* updateNotification(state, payload) {
      state.notifications = payload.notifications;
    },
    updateNotificationDate(state, payload) {
      state.notificationDate = payload.notificationDate;
    },
    updateSnapshotId(state, payload) {
      state.snapshotid = payload.snapshotid;
    },
    updateNotificationUnread(state, payload) {
      state.user.unread = payload.unread;
    },
    updateRedirectAnnouncements(state) {
      state.redirectAnnouncements = true;
    },
    updateDashboard(state, payload) {
      state.cms = payload.cms;
    },
    updateBulletins(state, payload) {
      state.bulletins = payload.bulletins;
    },
    addSingleCMS(state, payload) {
      state.cms.push(payload.cms);
    },
    updateSingleCMS(state, payload) {
      let data = state.cms;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.cms._id) {
          data[i] = payload.cms;
        }
      }
    },
    deleteSingleCMS(state, payload) {
      let data = state.cms;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.cms._id) {
          state.cms.splice(i, 1);
        }
      }
    },
    addBulletin(state, payload) {
      state.bulletins.push(payload.bulletin);
    },

    deleteBulletin(state, payload) {
      let data = state.bulletins;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.bulletin._id) {
          state.bulletins.splice(i, 1);
        }
      }
    } */
  },
  actions: {},
  modules: {},
  getters: {
    bookingsAndCustomers: state => {
      let bookings = state.bookings;
      let customers = state.users;
      for (var i = bookings.length - 1; i >= 0; i--) {
        let customerDetails = customers.find(
          c => c._id === bookings[i].customerID
        );
        let updatedBooking = bookings[i];
        updatedBooking.customerDetails = customerDetails;
        // bookings.splice(i, 1, updatedBooking)
        Vue.set(bookings, i, updatedBooking);
        // Vue.set(state.bookings.customerDetails, i, payload)
        // bookings[i].customerDetails = customers.find( c => c._id === bookings[i].customerID)
        // Vue.set(bookings, i, payload)
      }
      // filter out bookings with no customer details
      bookings = bookings.filter(b => b.customerDetails);
      return bookings;
      // let promises = [];
      // // ADD UNIT DATA TO EACH BOOKING
      // for (var i = bookings.length - 1; i >= 0; i--) {
      //   bookings[i].customerDetails = getCustomerByID(bookings[i].customerID, i);
      //   bookings[i].customerDetails.then(function (results) {
      //     bookings[results[1]].customerDetails = results[0];
      //   });
      //   // FUNCTION RETURNS A PROMISE, SO WE KEEP A RECORD OF ALL PROMISES IN AN ARRAY
      //   promises.push(bookings[i].customerDetails);
      // }

      // // ONCE ALL PROMISES ARE RETURNED, WE CAN RETURN THE UPDATED BOOKING DATA WHICH NOW INCLUDES UNIT DETAILS
      // Promise.all(promises).then((result) => {
      //   return bookings;
      // });
    },
    paymentLinksPaid: state => {
      let paymentLinks = state.paymentLinks;
      paymentLinks = paymentLinks.filter(
      pl => pl.charge.charges.data[0].status === 'successful'    );
      return paymentLinks;
    }
  }
});
